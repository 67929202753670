<template>
    <div class="game__preview game__preview--2d">
        <img width="1024" height="1024" :src="'./assets/games/front/' + art" :alt="title" class="box-art__fallback"
            onerror="this.src = './assets/Transparent.png';" />
    </div>
</template>

<script>
    export default {
        props: ['art', 'title']
    }
</script>

<style lang="scss" scoped>

</style>