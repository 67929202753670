var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("h3", [
        _vm._v(
          "Showing " +
            _vm._s(_vm.gameList.length) +
            " item" +
            _vm._s(_vm._f("pluralise")(_vm.gameList.length))
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "gameList", staticClass: "row" },
        _vm._l(_vm.gameList, function(game) {
          return _c(
            "div",
            { key: game.name, staticClass: "col-sm-3" },
            [_c("Game", { attrs: { game: game } })],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }