<template>
    <div class="game__preview game__preview--3d">
        <div @mouseover="mouseOver" @mouseout="mouseOut" :data-diagram="art" ref="marker" class="box-art__canvas"></div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import * as THREE from 'three';

    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
    import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
    import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';


    export default {
        props: ['art', 'title', 'observer', 'seen'],
        data() {
            return {
                sceneElements: [],
                renderer: null,
                active: false,
                ctx: null,
                elem: null,
                fn: null,
                rendererCanvas: null,
            }
        },
        methods: {
            render(time) {
                if (!this.active) return;

                time *= 0.001;

                // get the viewport relative position of this element
                const rect = this.elem.getBoundingClientRect();
                const { left, right, top, bottom, width, height } = rect;
                this.rendererCanvas = this.renderer.domElement;

                const isOffscreen =
                    bottom < 0 ||
                    top > window.innerHeight ||
                    right < 0 ||
                    left > window.innerWidth;

                if (!isOffscreen) {
                    // make sure the renderer's canvas is big enough
                    if (this.rendererCanvas.width < width || this.rendererCanvas.height < height) {
                        this.renderer.setSize(width, height, false);
                    }

                    // make sure the canvas for this area is the same size as the area
                    if (this.ctx.canvas.width !== width || this.ctx.canvas.height !== height) {
                        this.ctx.canvas.width = width;
                        this.ctx.canvas.height = height;
                    }

                    this.renderer.setScissor(0, 0, width, height);
                    this.renderer.setViewport(0, 0, width, height);

                    this.fn(time, rect);

                    // copy the rendered scene to this element's canvas
                    this.ctx.globalCompositeOperation = 'copy';
                    this.ctx.drawImage(
                        this.rendererCanvas,
                        0, this.rendererCanvas.height - height, width, height,  // src rect
                        0, 0, width, height);                              // dst rect
                }

                requestAnimationFrame(this.render);
            },

            addScene(elem, fn) {
                const ctx = document.createElement('canvas').getContext('2d');
                ctx.canvas.setAttribute('uid', this._uid);
                ctx.canvas.classList.add('game__preview-canvas');
                elem.appendChild(ctx.canvas);
                this.elem = elem;
                this.fn = fn;
                this.ctx = ctx;
            },

            setTexture(url, mesh) {
                const textureLoader = new THREE.TextureLoader();
                textureLoader.setCrossOrigin("anonymous");
                textureLoader.load('./assets/games/' + url, texture => {
                    if (mesh instanceof THREE.Mesh) {
                        mesh.material.map = texture
                        mesh.material.needsUpdate = true;
                        mesh.material.transparent = true;
                    }
                });
            },

            makeScene(elem) {
                const scene = new THREE.Scene();

                const fov = 45;
                const aspect = 1;  // the canvas default
                const near = 0.1;
                const far = 100;
                const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
                camera.position.set(0, 0, 12);
                camera.lookAt(0, 0, 0);
                scene.add(camera);

                const controls = new TrackballControls(camera, this.$refs.marker);
                controls.noZoom = false;
                controls.noPan = false;

                const ambientLight = new THREE.AmbientLight(0xffffff, 1);
                scene.add(ambientLight);

                // const light = new THREE.DirectionalLight( 0xffffff, .2 );
                // light.position.set( 1, 1, 1 );
                // scene.add( light );
                let light;

                light = new THREE.HemisphereLight(0xffffff, 0x444444, 1.0);
                light.position.set(0, 1, 0);
                scene.add(light);

                light = new THREE.DirectionalLight(0xffffff, 1.0);
                light.position.set(0, 1, 0);
                scene.add(light);

                // {
                //     const color = 0xFFFFFF;
                //     const intensity = 1;
                //     const light = new THREE.DirectionalLight(color, intensity);
                //     light.position.set(1,1,1);
                //     camera.add(light);
                // }

                return { scene, camera, controls };
            },

            onProgress(xhr) {
                if (xhr.lengthComputable) {
                    var percentComplete = xhr.loaded / xhr.total * 100;
                    console.log(Math.round(percentComplete, 2) + '% downloaded');
                }
            },

            onError(xhr) { },

            sceneInitFunctionsByName(texture) {
                const that = this;
                const { scene, camera, controls } = this.makeScene();
                const loader = new FBXLoader();
                loader.onProgress = function (item, loaded, total) {
                    console.log(item, loaded, total);
                };

                let mesh = null;
                loader.load('assets/halogame.fbx', function (object) {
                    object.traverse(function (child) {
                        if (child.isMesh) {
                            that.setTexture(texture, child);
                            child.castShadow = true;
                            child.receiveShadow = true;
                            mesh = child;
                        }
                    });

                    scene.add(object);
                }, this.onProgress, this.onError);

                return (time, rect) => {
                    if (mesh) mesh.rotation.z = time * .2;
                    camera.aspect = rect.width / rect.height;
                    camera.updateProjectionMatrix();
                    controls.handleResize();
                    controls.update();
                    this.renderer.render(scene, camera);
                };
            },

            initialiseBoxArt() {
                const canvas = this.$refs.canvasMarker;
                this.renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });
                this.renderer.setScissorTest(true);

                const sceneRenderFunction = this.sceneInitFunctionsByName(this.art);
                this.addScene(this.$refs.marker, sceneRenderFunction);
                requestAnimationFrame(this.render);
            },

            clear3D() {
                // document.querySelectorAll('canvas.game__preview-canvas').forEach(c => c.remove());
                document.querySelectorAll('canvas[uid="' + this._uid + '"]').forEach(c => c.remove());
                this.active = false;
            },

            mouseOver() {
                // if (!this.active) {
                //     this.initialiseBoxArt();
                // }
                // this.active = true;
            },

            mouseOut() {
                // this.renderer = null;
                // this.ctx = null;
                // document.querySelectorAll('canvas[uid="' + this._uid + '"]').forEach(c => c.remove());
                // this.active = false;
            }
        },
        mounted() {
            // this.observer.observe(this.$el);
            // if (this.render3D) {
            //this.initialiseBoxArt();
            // }
        },

        watch: {
            art(val) {
                // document.querySelectorAll('canvas[uid="' + this._uid + '"]').forEach(c => c.remove());
                // this.initialiseBoxArt();
            },

            seen(val) {
                if (val) {
                    document.querySelectorAll('canvas[uid="' + this._uid + '"]').forEach(c => c.remove());
                    this.initialiseBoxArt();
                    this.active = true;
                } else {
                    console.log("Cleaning", this.title);
                    this.clear3D();
                }

                console.log(`${this.title} is visible: ${val}`);

            }
        },

        destroyed() {
            console.log(`destroying ${this.title}`);
            this.active = false;
            this.clear3D();
            this.sceneElements = [];
            this.renderer = null;
            this.active = false;
            this.renderer = null;
            this.ctx = null;
            this.rendererCanvas = null;
        },

        computed: {
            ...mapState('games', [
                'render3D',
            ]),
        }
    }
</script>