<template>
    <div class="container">
        <h3>Render 3D</h3>

        <button class="btn" :class="{ 'btn-primary': this.render3D == true }" @click="toggleRenderMode(true)">On</button>
        <button class="btn" :class="{ 'btn-primary': this.render3D != true }"
            @click="toggleRenderMode(false)">Off</button>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {

            }
        },

        computed: {
            ...mapState('games', [
                'render3D',
            ]),
        },
        methods: {
            ...mapActions('games', [
                'toggleRenderMode'
            ])
        }
    }

</script>

<style lang="scss" scoped>

</style>