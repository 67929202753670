var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "filter" }, [
        _c("h2", [_vm._v("Genres")]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.genres, function(genre) {
            return _c(
              "li",
              {
                key: genre,
                class: { "is-active": genre == _vm.activeGenre },
                on: {
                  click: function($event) {
                    return _vm.filterByGenre(genre)
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(genre) +
                    "\n                "
                )
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }