var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game__preview game__preview--3d" }, [
    _c("div", {
      ref: "marker",
      staticClass: "box-art__canvas",
      attrs: { "data-diagram": _vm.art },
      on: { mouseover: _vm.mouseOver, mouseout: _vm.mouseOut }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }