<template>
    <section>
        <div class="container">
            <h3>Showing {{ gameList.length }} item{{ gameList.length | pluralise }}</h3>
            <div class="row" ref="gameList">
                <div class="col-sm-3" v-for="game in gameList" :key="game.name">
                    <Game :game="game" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import Game from './Game.vue';

    export default{
        data() {
            return {
                observer: null
            }
        },

        components: {
            Game,
        },

        computed: {
            ...mapGetters('games', [
                'seenItems'
            ]),

            ...mapState('games', [
                'gameList',
                'render3D',
            ]),
        },

        mounted() {
            // const observerOpts = {
            //     threshold: 0.1,
            //     rootMargin: '50px 0px 50px 0px'
            // };

            // this.observer = new IntersectionObserver((entries) => {
            //     entries.forEach(entry => {
            //         if (!entry.isIntersecting) return;

            //         const idx = entry.target.getAttribute('data-idx');
            //         if (!idx) return;

            //         this.gameList.filter(g => g.barcode === idx)[0].seen = entry.isIntersecting;
            //         this.updateGameList(this.gameList);
            //     });
            // }, observerOpts);

            // this.$refs.gameList?.querySelectorAll('.game').forEach(game => {
            //     this.observer.observe(game);
            // });
        },

        beforeDestroy() {
            // this.observer.disconnect();
        },

        methods: {
            ...mapMutations('games', [
                'updateGameList',
            ]),
            // onElementObserved(entries) {
            //     entries.forEach(({ target, isIntersecting }) => {
            //         const idx = target.getAttribute('data-idx');
            //         this.gameList.filter(g => g.barcode === idx)[0].seen = isIntersecting;
            //         this.updateGameList(this.gameList);
            //     });
            // }
        },

        filters: {
            pluralise(val) {
                if (val > 1) return "s";
                else return null;
            }
        }
    }

</script>

<style>

</style>