import Vue from 'vue';
import Vuex from 'vuex';
import games from './modules/games.js';
  
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        games,
    },
    strict: false
});

export default store;