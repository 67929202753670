var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h3", [_vm._v("Render 3D")]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        class: { "btn-primary": this.render3D == true },
        on: {
          click: function($event) {
            return _vm.toggleRenderMode(true)
          }
        }
      },
      [_vm._v("On")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        class: { "btn-primary": this.render3D != true },
        on: {
          click: function($event) {
            return _vm.toggleRenderMode(false)
          }
        }
      },
      [_vm._v("Off")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }