<template>
    <div class="game" :data-idx="game.barcode" ref="wrapper">
        <div class="game__viewer" :class="{ 'is-seen': inView }">
            <box-art-2d :title="game.Title" :art="game.coverArt"></box-art-2d>
            <box-art-3d :seen="inView" :title="game.Title" :art="game.coverArt"></box-art-3d>
        </div>

        <div class="game__details">
            <h2 v-if="game.Title">{{ game.Title }}</h2>
            <h3 v-if="game.releaseDate">{{ game.releaseDate }}</h3>
            <p>
                <strong style="color: white;">{{ game.genre }}</strong>
                <template v-if="game.developer">
                    <br>{{ game.developer }}
                </template>
                <template v-if="game.developer != game.publisher">
                    &nbsp;& {{ game.publisher }}
                </template>
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import boxArt3D from './BoxArt3D.vue';
    import boxArt2D from './BoxArt2D.vue';

    export default {
        props: ['game'],
        data() {
            return {
                inView: false,
                isInViewObserver:  null,
            }
        },

        mounted() {
            this.setupIntersectionObserver();
        },

        destroy() {
            if (this.isInViewObserver) {
                this.isInViewObserver.disconnect();
            }
        },

        methods: {
            setupIntersectionObserver() {
                const observerOptions = {
                    threshold: [0.1],
                    rootMargin: '20px 0px 20px 0px' // These values allow area space for the element to first be lazyloaded, this allows it to start when its "Out of the viewport"
                };

                this.isInViewObserver = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        this.inView = entry.isIntersecting;
                    });
                }, observerOptions);

                this.isInViewObserver.observe(this.$refs.wrapper);
            }
        },

        components: {
            'box-art-3d': boxArt3D,
            'box-art-2d': boxArt2D,
        },
    }

</script>

<style lang="scss" scoped>

</style>