var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "game",
      attrs: { "data-idx": _vm.game.barcode }
    },
    [
      _c(
        "div",
        { staticClass: "game__viewer", class: { "is-seen": _vm.inView } },
        [
          _c("box-art-2d", {
            attrs: { title: _vm.game.Title, art: _vm.game.coverArt }
          }),
          _vm._v(" "),
          _c("box-art-3d", {
            attrs: {
              seen: _vm.inView,
              title: _vm.game.Title,
              art: _vm.game.coverArt
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "game__details" }, [
        _vm.game.Title ? _c("h2", [_vm._v(_vm._s(_vm.game.Title))]) : _vm._e(),
        _vm._v(" "),
        _vm.game.releaseDate
          ? _c("h3", [_vm._v(_vm._s(_vm.game.releaseDate))])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          [
            _c("strong", { staticStyle: { color: "white" } }, [
              _vm._v(_vm._s(_vm.game.genre))
            ]),
            _vm._v(" "),
            _vm.game.developer
              ? [
                  _c("br"),
                  _vm._v(_vm._s(_vm.game.developer) + "\n            ")
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.game.developer != _vm.game.publisher
              ? [
                  _vm._v(
                    "\n                 & " +
                      _vm._s(_vm.game.publisher) +
                      "\n            "
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }