<template>
    <div v-cloak>
        <GenreFilter />
        <!-- <ToggleMode /> -->
        <GameList />
    </div>
</template>

<script>
    import GameList from './GameList.vue';
    import GenreFilter from './GenreFilter.vue';
    import ToggleMode from './ToggleMode.vue';

    export default {
        data() {
            return {

            }
        },
        components: {
            GameList,
            GenreFilter,
            ToggleMode,
        },
    }
</script>

<style>

</style>