<template>
    <section>
        <div class="container">
            <div class="filter">
                <h2>Genres</h2>
                <ul>
                    <li v-for="genre in genres" :key="genre" @click="filterByGenre(genre)"
                        :class="{ 'is-active': genre == activeGenre }">
                        {{ genre }}
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {

            }
        },
        computed: {
            ...mapState('games', [
                'genres',
                'activeGenre'
            ]),
        },
        methods: {
            ...mapActions('games', [
                'filterByGenre'
            ])
        }
    }
</script>

<style lang="scss" scoped>

</style>