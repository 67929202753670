import Vue from 'vue';

import Megadrive from './components/Megadrive.vue';
import store from './store/index.js';

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    render: h => h(Megadrive),
    store,
});