var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game__preview game__preview--2d" }, [
    _c("img", {
      staticClass: "box-art__fallback",
      attrs: {
        width: "1024",
        height: "1024",
        src: "./assets/games/front/" + _vm.art,
        alt: _vm.title,
        onerror: "this.src = './assets/Transparent.png';"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }