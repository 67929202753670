import Vue from 'vue';
import _ from 'lodash';

import games from '../../games.json';

let filteredList = games.list.filter(g => g.Order != -1);
filteredList = filteredList.sort((a, b) => +b.Favourite - +a.Favourite);

let genres = _.uniq(filteredList.map(g => g.genre));
genres = genres.filter(g => g != "");
genres.unshift('All');
console.log("Known genres", genres);

let developers = _.uniq(filteredList.map(g => g.developer));
developers = developers.filter(g => g != "");
developers.unshift('All');
console.log("Known developers", developers);

let publishers = _.uniq(filteredList.map(g => g.publisher));
publishers = publishers.filter(g => g != "");
publishers.unshift('All');
console.log("Known publishers", publishers);

// Add scene default
filteredList = filteredList.map(f => {
    f.seen = false;
    return f;
});

const state = {
    originalList: filteredList,
    gameList: filteredList,
    render3D: false,
    genres,
    activeGenre: 'All',
}

const getters = {
    seenItems: () => {
        return state.gameList.filter(g => g.seen === true);
    }
}

const mutations = {
    updateGameList(state, newList) {
        state.gameList = newList;
    },

    updateActiveGenre( state, newGenre) {
        state.activeGenre = newGenre;
    },

    updateRenderMode(state, newMode) {
        state.render3D = newMode;
    },
}


const actions = {
    filterByGenre({commit}, genre) {
        if (genre === "All") {
            commit('updateGameList', state.originalList);
        } else {
            const newList = state.originalList.filter(g => g.genre == genre);
            commit('updateGameList', newList);
        }
    
        commit('updateActiveGenre', genre);
    },

    resetList({commit}) {
        const newList = state.originalList;
        commit('updateGameList', newList);
    },

    showFavouriteList({commit}) {
        const newList = state.originalList.filter(g => g.favourite === "Yes");
        commit('updateGameList', newList);
    },

    toggleRenderMode( {commit}, toggle) {
        commit('updateRenderMode', toggle)
    },

    updateGameVisibilityList( {commit}, newList) {
        commit('updateGameList', newList);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}